import { Loader, MenuList } from '@customer/react-relax';
import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useMemo,
} from 'react';
import { withRouter } from 'react-router-dom';
import SalesCodeConversionsTable from '../Components/Table/SalesCodeConversionsTable';
import { Notification } from '../Elements/Notification';
import { OutsideClickHandler } from '../Elements/OutsideClickHandler';
import { NotificationType } from '../Enums/NotificationType';
import { GlobalContext } from '../GlobalContext';
import { formatMessage } from '../Localization/formatMessage';
import Messages from '../Localization/Messages';
import { ServiceClient } from '../Services/ServiceClient';
import './SalesCodeConversionPage.css';

const SalesCodeConversionPage = props => {
  const [salesCodeConversions, setSalesCodeConversions] = useState(null);
  const [showMenuForSalesCode, setShowMenuForSalesCode] = useState('');
  const [notifications, setNotifications] = useState([]);

  const memoizedSalesCodeConversions = useMemo(
    () =>
      salesCodeConversions
        ?.map(channel =>
          channel.salesCodeConversions.map(y => ({
            ...y,
            channel: channel,
          }))
        )
        .flat() ?? [],
    [salesCodeConversions]
  );

  const context = useContext(GlobalContext);

  const [loadingCounter, dispatchLoading] = useReducer((state, modifier) => {
    if (modifier === null) return 0;
    return state + modifier;
  }, 0);

  function addLoading() {
    dispatchLoading(1);
  }
  function subLoading() {
    dispatchLoading(-1);
  }

  //Enable/disable salesCodeItem in local state
  function changeSalesCodeConversionsListItemState(channelItem, salesCodeItem) {
    const newState = {
      isFutureChangeActive: !salesCodeItem.isFutureChangeActive,
      futureChangeStartDate: !salesCodeItem.isFutureChangeActive
        ? getFormattedDate(new Date(Date.now()))
        : null,
    };

    const cloneSalesCodeConversions = salesCodeConversions?.map(channel =>
      channel.channelNameInUserMetadata ===
      channelItem.channelNameInUserMetadata
        ? {
            ...channel,
            salesCodeConversions: channel.salesCodeConversions?.map(
              conversion =>
                conversion.oldSalesCode === salesCodeItem.oldSalesCode
                  ? { ...conversion, ...newState }
                  : conversion
            ),
          }
        : channel
    );
    setSalesCodeConversions(cloneSalesCodeConversions);
  }

  function pushNotification(type, text) {
    setNotifications([...notifications, { type, text }]);
  }

  function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString();
    const day = date.getDate().toString();

    return year + '/' + month + '/' + day;  
  }

  // update breadcrumbs on startup and load data
  useEffect(init, []);
  function init() {
    addLoading();
    ServiceClient.getSalesCodeConversions(
      data => {
        setSalesCodeConversions(data);
        subLoading();
      },
      () => {
        subLoading();
      }
    );
    updateBreadcrumbs();
  }

  function updateBreadcrumbs() {
    props.navigationAppend(
      'salesCodeConversionPageTitle',
      props.location?.pathname
    );
  }

  function isConversionActive(conversion) {
    return conversion?.isFutureChangeActive && conversion?.newSalesCode;
  }

  function disableConversion(channel, conversion) {
    ServiceClient.disableSalesCodeConversionForRenewal(
      conversion.oldSalesCode,
      context.accessToken,
      //Success
      () => {
        changeSalesCodeConversionsListItemState(channel, conversion);
        pushNotification(
          NotificationType.Success,
          formatMessage(Messages.salesCodeConversionPageMappingDisabled, {
            oldSalesCode: conversion.oldSalesCode,
            newSalesCode: conversion.newSalesCode,
          })
        );
      },
      //Error
      () => {
        pushNotification(
          NotificationType.Error,
          formatMessage(
            Messages.salesCodeConversionPageMappingErrorDuringDisable,
            {
              oldSalesCode: conversion.oldSalesCode,
              newSalesCode: conversion.newSalesCode,
            }
          )
        );
      }
    );
  }

  function enableConversion(channel, conversion) {
    ServiceClient.enableSalesCodeConversionForRenewal(
      conversion.oldSalesCode,
      conversion.newSalesCode, 
      context.accessToken,
      //Success
      () => {
        changeSalesCodeConversionsListItemState(channel, conversion);
        pushNotification(
          NotificationType.Success,
          formatMessage(Messages.salesCodeConversionPageMappingDone, {
            oldSalesCode: conversion.oldSalesCode,
            newSalesCode: conversion.newSalesCode,
          })
        );
      },
      () => {
        //Error
        pushNotification(
          NotificationType.Error,
          formatMessage(
            Messages.salesCodeConversionPageMappingErrorDuringMapping,
            {
              oldSalesCode: conversion.oldSalesCode,
              newSalesCode: conversion.newSalesCode,
            }
          )
        );
      }
    );
  }

  return (
    <div className="salescodeconversion-page">
      <h2>{formatMessage(Messages.salesCodeConversionPageTitle)}</h2>
      {!!loadingCounter && (
        <div className="loading-container">
          <Loader />
        </div>
      )}
      {!loadingCounter && (
        <>
          <h3>{formatMessage(Messages.salesCodeConversionPageStatistics)}</h3>
          <table className="statistics-table relax-table relax-table--dense">
            <thead>
              <tr>
                <th className="width-18">
                  {formatMessage(
                    Messages.salesCodeConversionPageChannelNameInUserMetadata
                  )}
                </th>
                <th className="width-10">
                  {formatMessage(Messages.salesCodeConversionPageGlCode)}
                </th>
                <th className="width-18">
                  {formatMessage(
                    Messages.salesCodeConversionPageChannelNameInWayPoint
                  )}
                </th>
                <th className="width-18">
                  {formatMessage(
                    Messages.salesCodeConversionPageSalesCodesInUserMetadata
                  )}
                </th>
                <th className="width-18">
                  {formatMessage(
                    Messages.salesCodeConversionPageConvertedSalesCodesToWayPoint
                  )}
                </th>
                <th className="width-18">
                  {formatMessage(
                    Messages.salesCodeConversionPageConvertedSalesCodesMappedInRenewalStep
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {salesCodeConversions?.map(x => (
                <tr key={x.channelNameInUserMetadata}>
                  <td>{x.channelNameInUserMetadata}</td>
                  <td>{x.glCode}</td>
                  <td>{x.channelNameInWayPoint}</td>
                  <td>{x.salesCodeConversions?.length}</td>
                  <td>
                    {
                      x.salesCodeConversions?.filter(x => !!x.newSalesCode)
                        .length
                    }
                  </td>
                  <td>
                    {
                      x.salesCodeConversions?.filter(
                        x => x.isFutureChangeActive && x.newSalesCode
                      ).length
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <h3>{formatMessage(Messages.salesCodeConversionPageSalescodes)}</h3>
          {notifications.map((notification, i) => (
            <Notification key={i} type={notification.type}>
              {notification.text}
            </Notification>
          ))}
          <div className="salescodes-conversions-table-container">
            <div>
              <SalesCodeConversionsTable
                data={memoizedSalesCodeConversions}
                action={conversion => (
                  <>
                    <button
                      className="salescode-tile-menu"
                      onClick={() =>
                        setShowMenuForSalesCode(conversion.oldSalesCode)
                      }
                    />
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        if (showMenuForSalesCode === conversion.oldSalesCode) {
                          setShowMenuForSalesCode(null);
                        }
                      }}>
                      <MenuList
                        className="menulist"
                        isOpen={
                          showMenuForSalesCode === conversion.oldSalesCode
                        }>
                        <li>
                          <button
                            disabled={
                              isConversionActive(conversion) ||
                              !conversion.newSalesCode
                            }
                            onClick={() => {
                              enableConversion(conversion.channel, conversion);
                              setShowMenuForSalesCode(null);
                            }}>
                            {formatMessage(
                              Messages.salesCodeConversionPageEnableButton
                            )}
                          </button>
                        </li>
                        <li>
                          <button
                            disabled={!isConversionActive(conversion)}
                            onClick={() => {
                              disableConversion(conversion.channel, conversion);
                              setShowMenuForSalesCode(null);
                            }}>
                            {formatMessage(
                              Messages.salesCodeConversionPageDisableButton
                            )}
                          </button>
                        </li>
                      </MenuList>
                    </OutsideClickHandler>
                  </>
                )}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(SalesCodeConversionPage);
