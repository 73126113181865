import React from 'react';
import ScriptLoader from 'react-render-props-script-loader';
import { injectIntl } from 'react-intl';
import config from '../config/variables';
import { withRouter } from 'react-router-dom';
import Messages from '../Localization/Messages';
import { useAuth } from '../context/AuthContext';

function GlobalheaderWithUserInfo(props) {
  const { user } = useAuth();

  return (
    <global-header
      bff-user={JSON.stringify({
        domainAlias: user.identityProvider,
        fullName: user.fullName,
        firstName: user.givenName,
        lastName: user.familyName,
        preferredUserName: user.preferredUsername,
      })}
      consumer="sales-agent-app"
      version="2"
      is-bff={true}
      bff-route-prefixes={JSON.stringify({
        userPreferences: '',
        cms: '/api/cms',
      })}
      ref={props.originalRef}
      breadcrumbs={props.breadcrumbs}
    />
  );
}

class GlobalHeader extends React.Component {
  constructor(props) {
    super(props);
    this.globalHeader = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    const globalHeader = this.globalHeader.current;
    if (!globalHeader) return;

    const eventArgs = { passive: true };

    globalHeader.addEventListener(
      'global-header:preferences-updated',
      this.handlePreferencesUpdated,
      eventArgs
    );
    globalHeader.addEventListener(
      'global-header:app-page-link-clicked',
      this.handleAppPageLinkClicked,
      eventArgs
    );
  }

  componentWillUnmount() {
    const globalHeader = this.globalHeader.current;
    if (!globalHeader) return;

    globalHeader.removeEventListener(
      'global-header:preferences-updated',
      this.handlePreferencesUpdated
    );
    globalHeader.removeEventListener(
      'global-header:app-page-link-clicked',
      this.handleAppPageLinkClicked
    );
  }

  handleAppPageLinkClicked = event => {
    if (!event.detail)
      console.warn('Missing path details for app page link event', event);

    const path = event.detail || '/';
    this.props.history.push(path);
  };

  handlePreferencesUpdated = event => {
    const preferences = event.detail;
    console.log('handlePreferencesUpdated', preferences);
    if (this.props.handleUserPreferences) {
      this.props.handleUserPreferences(preferences);
    }
    if (this.props.onChangeLanguage) {
      this.props.onChangeLanguage(preferences.languageCode);
    }
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    const breadcrumbs = this.props.breadcrumbs.map(x => {
      return {
        link: x.address,
        title: formatMessage(Messages[x.id]),
        trackingName: x.id,
      };
    });
    return (
      <>
        <GlobalheaderWithUserInfo
          originalRef={this.globalHeader}
          breadcrumbs={JSON.stringify(breadcrumbs)}></GlobalheaderWithUserInfo>
      </>
    );
  }
}

const ConnectedGlobalHeader = injectIntl(withRouter(GlobalHeader));

const GlobalHeaderWithLoader = ({ ...params }) => {
  const topMenuComponentUrl = config.components.globalHeader;
  return (
    <ScriptLoader type="text/javascript" src={topMenuComponentUrl}>
      {({ loading }) => {
        if (loading) return null;
        return <ConnectedGlobalHeader {...params} />;
      }}
    </ScriptLoader>
  );
};

export default GlobalHeaderWithLoader;
